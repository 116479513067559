
import {
  IonApp,
  IonIcon,
  IonItem,
  IonContent,
  IonRouterOutlet,
  IonMenu,
  IonMenuToggle,
  IonSplitPane,
} from "@ionic/vue";
import { defineComponent, ref } from "vue";
import { useRoute } from "vue-router";
import {
  informationCircleOutline,
  informationCircleSharp,
  pricetagOutline,
  pricetagSharp,
  chatbubblesOutline,
  chatbubblesSharp,
  mailOutline,
  mailSharp,
  bulbOutline,
  bulbSharp,
  newspaperOutline,
  newspaperSharp,
  happyOutline,
  happySharp,
} from "ionicons/icons";

export default defineComponent({
  name: "App",
  components: {
    IonApp,
    IonIcon,
    IonItem,
    IonContent,
    IonRouterOutlet,
    IonMenu,
    IonMenuToggle,
    IonSplitPane,
  },
  setup() {
    const selectedIndex = ref(0);
    const appPages = [
      {
        title: "八卦版QA檢索",
        pageId: "QA",
        url: "/demo/QA",
        iosIcon: chatbubblesOutline,
        mdIcon: chatbubblesSharp,
      },
      {
        title: "資訊抽取", // Information Extraction
        pageId: "BasicInfo",
        url: "/demo/BasicInfo",
        iosIcon: informationCircleOutline,
        mdIcon: informationCircleSharp,
      },
      // {
      //   title: "新聞分類",
      //   pageId: "NewsPaper",
      //   url: "/demo/NewsPaper",
      //   iosIcon: newspaperOutline,
      //   mdIcon: newspaperSharp,
      // },
      // {
      //   title: "NER",
      //   pageId: "NER",
      //   url: "/demo/NER",
      //   iosIcon: pricetagOutline,
      //   mdIcon: pricetagSharp,
      // },
      // {
      //   title: "履歷與JD相符程度",
      //   pageId: "JD",
      //   url: "/demo/JD",
      //   iosIcon: mailOutline,
      //   mdIcon: mailSharp,
      // },
      // {
      //   title: "職位種類(PJM, PDM, PGM)",
      //   pageId: "JDType",
      //   url: "/demo/JDType",
      //   iosIcon: mailOutline,
      //   mdIcon: mailSharp,
      // },
      {
        title: "情感分析",
        pageId: "SentimentAnalysis",
        url: "/demo/SentimentAnalysis",
        iosIcon: happyOutline,
        mdIcon: happySharp,
      },
      {
        title: "____書寫建議",
        pageId: "Suggestion",
        url: "/demo/Suggestion",
        iosIcon: bulbOutline,
        mdIcon: bulbSharp,
      },
    ];
    const path = window.location.pathname.split("folder/")[1];
    if (path !== undefined) {
      selectedIndex.value = appPages.findIndex(
        // (page) => page.title.toLowerCase() === path.toLowerCase()
        (page) => page.pageId.toLowerCase() === path.toLowerCase()
      );
    }

    const route = useRoute();

    return {
      selectedIndex,
      appPages,
      path,
      isSelected: (url: string) => (url === route.path ? "selected" : ""),
    };
  },
});
