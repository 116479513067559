import { createRouter, createWebHistory } from '@ionic/vue-router';
import { RouteRecordRaw } from 'vue-router';

const routes: Array<RouteRecordRaw> = [
  {
    path: '',
    redirect: '/demo/QA'
  },
  {
    path: '/demo/:pageId',
    component: () => import('../views/HomePage.vue'),
    beforeEnter: (to, _, next) => {
      const { pageId } = to.params
      console.log("RRR");
      console.log(pageId);
      if (Array.isArray(pageId)) {
        next({ path: '/error' })
        return
      }

      // // Is a valid index number
      // const index = parseInt(id)
      // if (index < 0 || index >= facts.length) {
      //   next({ path: '/error' })
      //   return
      // }

      next()
    }
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
